import React from 'react';
import { Helmet } from 'react-helmet';

function ChangeTitle({ title }) {
	return (
		<Helmet>
			<title>{title} - 121Labs</title>
		</Helmet>
	)
}

export default ChangeTitle;