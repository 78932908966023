import React from 'react';

import './styles.scss';

function BoxServices(props) {
  return (
    <article className={`container-boxServices ${props.className}`}>
      <div 
        className="boxService-circle"
        style={{"backgroundColor": props.backgroudColor}}
      ></div>

      <div className="container-text-boxService">
        <h1
          className="title-BoxService"
          style={{"color": props.backgroudColor}}
        >
          {props.title}
        </h1>

        <div className="container-subtitle-boxService">
          <h3 className="text-BoxService">
            {props.text1}
          </h3>

          <h3 className="text-BoxService">
            {props.text2}
          </h3>
        </div>
      </div>
    </article>        
  );
}

export default BoxServices;
