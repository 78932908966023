import React, { useContext } from "react";
import { MainContext } from "../../../Context/mainContext";
import User121Menu from "./User121Menu/";
import AnyUserMenu from "./AnyUserMenu/";
import AuthService from "../../../Services/authService";
import { useHistory } from "react-router-dom";

function BtnUserConfigs() {
  const History = useHistory();
  const { user, ResetInfosSignOut } = useContext(MainContext);

  const SignOut = (evt) => {
    evt.preventDefault();
    AuthService.signOut();
    History.push("/login");
    ResetInfosSignOut();
  };

  if (user.companyInfo !== undefined) {
    if (user.companyInfo.nome === "121labs") {
      return <User121Menu SignOut={(e) => SignOut(e)} />;
    } else {
      return <AnyUserMenu SignOut={(e) => SignOut(e)} />;
    }
  } else {
    return null;
  }
}

export default BtnUserConfigs;
