import React from 'react';
import './styles.scss';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { MainContext } from '../../Context/mainContext';

export function Input(props) {
	const { inputValue, setInputValue } = React.useContext(MainContext);

	return (
    <input
      onChange={evt => setInputValue({...inputValue, [evt.target.name]: evt.target.value})}
      type={props.type}
      name={props.name}
      id={props.name}
      placeholder={props.placeholder}
      required
    />
	)
}

export function InputPassword(props) {
	const { inputValue, setInputValue } = React.useContext(MainContext);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

	return (
		<>
	    <input
	      onChange={evt => setInputValue({...inputValue, [evt.target.name]: evt.target.value})}	      
	      type={passwordVisible ? "text" : "password"}
	      name={props.name}
	      placeholder={props.placeholder}
        required	      
	    />

	    {passwordVisible ? <AiFillEyeInvisible onClick={() => setPasswordVisible(!passwordVisible)} className="visiblePassword iconNotVisible"/> : <AiFillEye onClick={() => setPasswordVisible(!passwordVisible)} className="visiblePassword iconVisible"/> }
		</>
	)
}