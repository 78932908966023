import React from 'react';

import './styles.scss';

import Instagram from '../../Assets/img/social-media-icons/svg/instagram.svg';
import Linkedin from '../../Assets/img/social-media-icons/svg/linkedin.svg';
import Youtube from '../../Assets/img/social-media-icons/svg/youtube.svg';
import Facebook from '../../Assets/img/social-media-icons/svg/facebook.svg';

function RedesSociais() {
  return (
    <div className="container-redes-sociais">
      <a 
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.linkedin.com/company/121-mktstation/">
        <img 
          className="redesSociaisLogoRedeSocial"
          src={Linkedin}
          alt="Linkedin"
        />
      </a>

      <a 
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.instagram.com/121labss/">
        <img 
          className="redesSociaisLogoRedeSocial"
          src={Instagram}
          alt="Instagram"
        />
      </a>

      <a 
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.youtube.com/channel/UCb7FOZtxofwaT7MQFh3_yjg">
        <img 
          className="redesSociaisLogoRedeSocial"
          src={Youtube}
          alt="Youtube"
        />
      </a>

      <a 
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/121Labs/">
        <img 
          className="redesSociaisLogoRedeSocial"
          src={Facebook}
          alt="Facebook"
        />
      </a>
    </div>
  );
}

export default RedesSociais;
