import React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./private";
import LoginRoute from "./login";

import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Welcome from "../Pages/Welcome";
import ContactUs from "../Pages/ContactUs";
import NewPassword from "../Pages/NewPassword";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import NewPrivacyPolicy from "../Pages/NewPrivacyPolicy";
import PageNotFound from "../Pages/NotFound";

import { MainProvider } from "../Context/mainContext";

const Routes = () => {
  return (
    <MainProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/privacy-policy-total-experience-cnu" component={PrivacyPolicy} />
          <Route exact path="/politica-privacidade" component={NewPrivacyPolicy} />
          <LoginRoute path="/login" component={Login} />
          <PrivateRoute path="/relatorios-pesquisa" component={Welcome} />
          <PrivateRoute path="/faleconosco" component={ContactUs} />
          <PrivateRoute path="/alterarsenha" component={NewPassword} />
          <Route exact path="*" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </MainProvider>
  );
};

export default Routes;
