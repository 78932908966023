import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import NotFoundIllustration from '../../Assets/img/404_page_not_found_.svg';
import Layout from '../../Components/Layout';

function PageNotFound() {
	return (
		<Layout title="Página não encontrada">
			<article id="container-notfound">
			 		<img src={ NotFoundIllustration } alt="Ilustração de um homem procurando algo" />
					<h3>A página que você procurou não existe ou foi removido!</h3>
					<Link to="/">
						<button>Voltar para o Inicio</button>
					</Link>
	    </article>
	  </Layout>
	)
}

export default PageNotFound;