
import { firebaseFirestore } from '../Config/firebase.js';

export default class reportService {
  static getReport = async (docId) => {
    return firebaseFirestore.collection('relatorios').doc(docId).get();
  }

  static getAllReports = async () => {
    let querySnapshot = await firebaseFirestore.collection('relatorios').orderBy('id', 'desc').get();
    let arrayReports = [];

    querySnapshot.forEach(document => {
      arrayReports.push({
        id: document.id,
        data: document.data()
      });
    });

    return arrayReports;
  }
}

