import React from 'react';
import { MainContext } from '../../Context/mainContext';

import './styles.scss';

function FilterOptions() {
  const { viewFilter, setViewFilter, FilterListMobile, ResetDataToView } = React.useContext(MainContext);

	return (
		<div className="filter-options" style={{width: viewFilter ? '250px' : 0}}>
      <header onClick={() => setViewFilter(!viewFilter)}>
        <div className="container-header">
        	<span className="close-icon">&times;</span>
        	<h5>Filtro</h5>
        </div>
      </header>

      <form>
        <section className="body-filter-options">
          <div className="container-filter">
            <input type="radio" id="customer-checkbox" name="filter" onClick={() => FilterListMobile('Customer Experience')}/>
            <label htmlFor="customer-checkbox">Customer Experience</label>
          </div>

          <div className="container-filter">
            <input type="radio" id="brand-checkbox" name="filter" onClick={() => FilterListMobile('Brand Experience')}/>
            <label htmlFor="brand-checkbox">Brand Experience</label>
          </div>

          <div className="container-filter">
            <input type="radio" id="product-checkbox" name="filter" onClick={() => FilterListMobile('Product Experience')}/>
            <label htmlFor="product-checkbox">Product Experience</label>
          </div>

          <div className="container-filter">
            <input type="radio" id="employee-checkbox" name="filter" onClick={() => FilterListMobile('Employee Experience')}/>
            <label htmlFor="employee-checkbox">Employee Experience</label>
          </div>

          <div className="container-filter">
            <input type="radio" id="gift-checkbox" name="filter" onClick={() => FilterListMobile('Gift Surveys')}/>
            <label htmlFor="gift-checkbox">Gift Surveys (Bônus)</label>
          </div>
        </section>

        <section className="button-reset-bottom" onClick={() => ResetDataToView()}>
          <button type="reset" className="button-reset">Reset</button>
        </section>
      </form>
    </div>
	)
}

export default FilterOptions;