import { firebaseFirestore } from "../Config/firebase.js";

export default class ProjectService {
  // Serviço para criar um novo projeto
  static createNewProject = (titulo, objetivo, dataInicio, telefone, email) => {
    let project = {
      titulo,
      objetivo,
      dataInicio,
      telefone,
      email,
      dataDaSolicitacao: new Date().toLocaleString("pt-BR", {
        timeZone: "America/Sao_paulo",
      }),
    };
    return firebaseFirestore.collection("novoProjeto").add(project);
  };

  // Serviço para pegar os projetos do usuário logado
  static getCurrentUserProjects = async (arrayReportIds) => {
    let querySnapshot = await firebaseFirestore.collection("relatorios").get();
    let arrayProjectsIds = [];
    querySnapshot.forEach((document) => {
      if (arrayReportIds.indexOf(document.id) !== -1) {
        arrayProjectsIds.push(document.data().id_projeto);
      }
    });

    querySnapshot = await firebaseFirestore.collection("projetos").get();
    let projects = [];
    querySnapshot.forEach((document) => {
      if (arrayProjectsIds.indexOf(document.id) !== -1) {
        let object = document.data();
        object.id = document.id;
        projects.push(object);
      }
    });

    return projects;
  };

  // Serviço para pegar todos os projetos
  static getAllProjects = async () => {
    let querySnapshot = await firebaseFirestore.collection("projetos").get();
    let projects = [];

    querySnapshot.forEach((document) => {
      let object = document.data();
      projects.push({ id: document.id, data: object });
    });

    return projects;
  };
}
