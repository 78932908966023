import React from "react";
import "./styles.scss";
import Footer from "../../Components/Footer";
import BoxServices from "../../Components/BoxServices";
import RedesSociais from "../../Components/RedesSociais";
import Logo from "../../Assets/img/logo121.png";
import { Link } from "react-router-dom";
import ChangeTitle from "../../Components/Helmet";
import RedirectToExternal from "../../Components/RedirectToExternal";

function Home() {
	return (
		<>
			<RedirectToExternal url="https://hazo.me" />
			<ChangeTitle title="Inicio" />

			<header id="header-home">
				<Link to="/login">
					<button>Login</button>
				</Link>
			</header>

			<section id="banner-home">
				<div id="container-text-banner">
					<img className="logo121Home" src={Logo} alt="website logo" />
					<h1>4.500.000+ INTERAÇÕES</h1>
					<h3>Decisões e inovações baseadas em dados</h3>
					<h3>
						da fonte mais importante que você tem:
						<span>seus clientes</span>
					</h3>
				</div>
			</section>

			<section id="about-home">
				<section id="container-about-home">
					<h1>CONHEÇA A INTERAÇÃO 121 LABS EM 30 SEGUNDOS!</h1>
					<iframe
						id="youtube-area-home"
						src="https://www.youtube.com/embed/h6OcnE5oXPk"
						title="Conheça a interação 121 labs em 30 segundos!"
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</section>
			</section>

			<section id="redes-sociais-home">
				<div id="container-redes-sociais-home">
					<h3>SIGA NOSSAS REDES SOCIAIS</h3>
					<div id="container-icon-home">
						<RedesSociais />
					</div>
				</div>
			</section>

			<section id="services-area-home">
				<header>
					<h1>IRM INTELLIGENCE RELATIONSHIP MANAGEMENT</h1>
					<h3>UMA SOLUÇÃO COMPLETA EM CUSTOMER EXPERIENCE MANAGEMENT (CXM)</h3>
				</header>

				<section className="container-services-home">
					<BoxServices
						backgroudColor="#8DED0C"
						title="Marca"
						text1="Brand"
						text2="Experience"
						className="brand-boxServices-home"
					/>
					<BoxServices
						backgroudColor="#3A386B"
						title="Clientes"
						text1="Customer"
						text2="Experience"
						className="clients-boxServices-home"
					/>

					<BoxServices
						backgroudColor="#1F89FF"
						title="Equipe"
						text1="Employee"
						text2="Experience"
						className="team-boxServices-home"
					/>
					<BoxServices
						backgroudColor="#36D7CA"
						title="Produto/Serviço"
						text1="Product"
						text2="Experience"
						className="product-boxServices-home"
					/>
				</section>
			</section>

			<Footer />
		</>
	);
}

export default Home;
