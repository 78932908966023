import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';

import AuthService from "../../Services/authService";
import { notification } from "../../Services/toastService";
import { MainContext } from '../../Context/mainContext';

import Loader from "../../Components/Loader";
import ArrowGoBack from "../../Components/ArrowGoBack";
import { InputPassword } from '../../Components/Inputs/';
import Layout from '../../Components/Layout';

import './styles.scss';

function NewPassword() {
  const { loading, inputValue, setInputValue } = useContext(MainContext);
  const history = useHistory();

  const handleForm = async (evt) => {
    evt.preventDefault();
    const { currentPassword, newPassword, repeatNewPassword } = inputValue;

    if (newPassword === repeatNewPassword) {
      const status = await AuthService.reAuth(
        currentPassword,
        newPassword
      );

      if (status.type === "success") {
        notification(status.message, status.type);
        setInputValue({
          ...inputValue,
          currentPassword: '',
          newPassword: '',
          repeatNewPassword: ''
        })
        history.goBack();          
      } else {
        notification(status.message, status.type);
      }
    } else {
      notification("Novas senhas não conferem");
    }
  }

  if (loading) {
    return <Loader />;
  } else {
    return (
       <Layout title="Nova Senha">
        <article id="container-alterarSenha">
          <ArrowGoBack route="" history={history}/>

          <form id="formNewPassword" onSubmit={evt => handleForm(evt)}>
            <header id="container-header-form-alterarSenha">
              <h1>ALTERAR SENHA</h1>
            </header>

            <section id="container-form-alterarSenha">
              <section className="container-input-alterarSenha">
                <label htmlFor="nome">SENHA ATUAL</label>
                <InputPassword 
                  name="currentPassword"                
                  placeholder="DIGITE SUA SENHA ATUAL"                   
                  value={inputValue.currentPassword}
                />
              </section>

              <section className="container-input-alterarSenha">
                <label htmlFor="fone">NOVA SENHA</label>
                <InputPassword 
                  name="newPassword"
                  placeholder="DIGITE SUA NOVA SENHA" 
                  value={inputValue.newPassowrd}
                />
              </section>

              <section className="container-input-alterarSenha">
                <label htmlFor="assunto">CONFIRMAR NOVA SENHA</label>
                <InputPassword 
                  name="repeatNewPassword"
                  placeholder="REPITA SUA NOVA SENHA"
                  value={inputValue.repeatNewPassword}
                  required
               />
              </section>

              <section id="container-buttons">
                <button className="btn" type="submit">ALTERAR SENHA</button>
                <button onClick={() => history.goBack()}>CANCELAR</button>
              </section>
            </section>
          </form>
        </article>
      </Layout>
    );
  }
}

export default NewPassword;
