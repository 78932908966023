import { firebaseFirestore } from '../Config/firebase.js';

export default class ThemeService {
	// Pega um tema especifico utilizando um nome
	static getSpecificTheme = async (idTheme = "") => {
		let docRef = await firebaseFirestore.collection("tema").doc(idTheme);
		let theme;
	 	
	 	await docRef.get().then(doc => {
      if (doc.exists) {
        theme = JSON.parse(doc.data().json);
      } else {
        console.error("Linha 15: No such document!");
      }
    }).catch(function(error) {
        console.error("Error getting document:", error);
    });

    return theme;	
	}

	// Pega todos os temas
	static getAllThemes = async () => {
		let querySnapshot = await firebaseFirestore.collection('tema').get();
		let themes = [];
	 	
	 	querySnapshot.forEach(document => {
    	themes.push({
    		id: document.id,
    		data: document.data()
    	});
  	})

    return themes;
	}

	// Atualiza um tema
	static updateTheme = async (name, json) => {
		let querySnapshot = await firebaseFirestore.collection("tema").where("nome", "==", name).get();
	 	
	 	querySnapshot.forEach(document => {
			firebaseFirestore.collection("tema").doc(document.id).set({
				nome: name,
				json: json
			})
		})
	}	

	// Remove um tema do banco
	static removeTheme = async (name) => {
		let querySnapshot = await firebaseFirestore.collection("tema").where("nome", "==", name).get();
	 	
	 	querySnapshot.forEach(document => {
			firebaseFirestore.collection("tema").doc(document.id).delete();
		});	
	}

	// Cria um tema no banco
	static createTheme = async (nome, json) => {
		let theme = {
			nome,
			json
		}
		
		await firebaseFirestore.collection("tema").add(theme);
	}

	// Associa um tema a outro
	static connectTheme = async (json) => {
		let querySnapshot = await firebaseFirestore.collection("tema").where("nome", "==", json.nameTheme).get();
	 	let theme;

	 	querySnapshot.forEach(document => {
	 		theme = document.data().json;
		});

		for (let i = 0; i < json.themes.length; i++) {
			this.updateTheme(json.themes[i], theme);
		}

		return await this.getAllThemes();
	}
}