import { firebaseFirestore } from '../Config/firebase.js';

export default class contactUsService {

  // Serviço requisição de um novo "fale conosco"
  static createNewContactUs = (nome, email, telefone, assunto, mensagem) => {
    let project = { 
      nome, 
      email, 
      telefone, 
      assunto, 
      mensagem, 
      dataDaMensagem: new Date().toLocaleString("pt-BR", {timeZone: "America/Sao_paulo"})
    };
    return firebaseFirestore.collection('faleConosco').add(project);
  }
}

