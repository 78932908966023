import React from 'react';

import './styles.scss';
import Logo from '../../Assets/img/logo121.png';

function Footer() {
  return (
    <footer className="footer-main">
    	<section className="container-footer">
	      <img className="footerLogo121" src={Logo} alt="website logo" />
	      <h3>
	        A 121 LABS É UM LABORATÓRIO DE INOVAÇÃO EM EXPERIÊNCIA DO CONSUMIDOR, <br />
	        EM BUSCA DE OPORTUNIDADES DE CRESCIMENTO PARA NOSSOS CLIENTES.
	      </h3>
      </section>
    </footer>
  );
}

export default Footer;
