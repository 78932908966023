import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MainContext } from '../../../Context/mainContext';

function MenuHamburguer() {
	const { user, setOpenSidebar, openSidebar } = React.useContext(MainContext);

	if (user.companyInfo !== undefined) {
    if (user.companyInfo.nome === '121labs') {
      return <GiHamburgerMenu onClick={() => setOpenSidebar({ ...openSidebar, User121: !openSidebar.User121 })}/>		
    }else {
      return <GiHamburgerMenu onClick={() => setOpenSidebar({ ...openSidebar, AnyUser: !openSidebar.AnyUser })}/>		
    }
  } else {
    return null;
  }
}

export default MenuHamburguer;