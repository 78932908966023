import React from "react";
import { useHistory } from 'react-router-dom';

import Logo from "../../Assets/img/logo121.png";

import ArrowGoBack from '../../Components/ArrowGoBack';
import { Input, InputPassword } from '../../Components/Inputs';
import ReactHelmet from '../../Components/Helmet';

import { MainContext } from '../../Context/mainContext';

import AuthService from "../../Services/authService";
import { notification } from "../../Services/toastService";

import './styles.scss';

function Login() {
  const { inputValue } = React.useContext(MainContext);
  const history = useHistory();

  const handleLogin = async (evt) => {
    evt.preventDefault();
    const { emailLogin, passwordLogin } = inputValue;
    await AuthService.signIn(emailLogin, passwordLogin)
      .then(() => {
        history.push('/relatorios-pesquisa');
      })
      .catch(err => {
        notification(err.message, "warning");
      });
  }

  return (
    <article id="container-login">
      <ReactHelmet title="Login"/>

      <ArrowGoBack route="/" history={history}/>

      <form id="formLogin" onSubmit={(evt) => handleLogin(evt)}>
        <div id="container-form-login">      
          <header id="header-form-login">
            <img className="loginLogo121" src={Logo} alt="website logo" />
            <h3>Welcome to the our Experience Lab</h3>

            <hr />
          </header>

          <article id="container-inputs-login">
            <section className="container-input-login">
              <Input type="email" name="emailLogin" placeholder="E-MAIL" />
            </section>

            <section className="container-input-login">
              <InputPassword name="passwordLogin" placeholder="SENHA"/>              
            </section> 

            <section id="container-btnLogin">
              <button id="btnLogin" type="submit">Login</button> 
            </section>
          </article>
        </div>
      </form>
    </article>
  )
}

export default Login;