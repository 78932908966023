/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// Services
import ThemeService from "../Services/themeService";
import UserService from "../Services/userService";
import { Auth } from "../Services/authService";
import ReportService from "../Services/reportService";
import ProjectService from "../Services/projectService";

// Components
import Container from "../styles/themes/theme";
import { ThemeProvider } from "styled-components";
import initialValues from "./initiaValues.json";

const MainContext = React.createContext();

function MainProvider({ children }) {
  const [user, setUser] = React.useState(initialValues.user);
  const [openSidebar, setOpenSidebar] = React.useState(
    initialValues.openSidebar
  );
  const [inputValue, setInputValue] = React.useState({});
  const [currentTheme, setCurrentTheme] = React.useState({});
  const [reports, setReports] = React.useState(null);
  const [themes, setThemes] = React.useState([]);
  const [companies, setCompanies] = React.useState(null);
  const [projects, setProjects] = React.useState([]);
  const [countSurveys, setCountSurveys] = React.useState(
    initialValues.countSurveys
  );
  const [infoDashboard, setInfoDashboard] = React.useState(
    initialValues.infoDashboard
  );
  const [viewFilter, setViewFilter] = React.useState(false);
  const [paginateConfigs, setPaginateConfigs] = React.useState(
    initialValues.paginateConfigs
  );
  const [userInformation, setUserInformation] = React.useState(null);

  const getUser = async () => {
    let result = getCompany(userInformation.empresa);

    if (result[0] !== undefined) {
      setUser({
        authenticated: true,
        userInformation,
        companyInfo: {
          nome: result[0].data.nome,
          themeId: result[0].data.tema_id,
        },
      });

      getTheme(result[0].data.tema_id);
    }
  };

  const getAll = async () => {
    let user = await UserService.getUserInformation();
    let projects = await ProjectService.getAllProjects();
    let themes = await ThemeService.getAllThemes();
    let companies = await UserService.getAllCompanies();
    let reports = await ReportService.getAllReports();

    setProjects(projects);
    setThemes(themes);
    setCompanies(companies);
    setUserInformation(user);

    if (user.email.match("121labs.io") !== null) {
      setReports(reports);

      ConvertIdReportToString(reports, projects);
    } else {
      let resultReport = getReport(reports, user.relatorios);

      setReports(resultReport);

      ConvertIdReportToString(resultReport, projects);
    }
  };

  const ConvertIdReportToString = (reports, projects) => {
    let tmpCountSurveys = {
      customer: 0,
      brand: 0,
      product: 0,
      employee: 0,
      gift: 0,
    };

    for (let i = 0; i < reports.length; i++) {
      let result = projects.filter(
        ({ id }) => id === reports[i].data.id_projeto
      );

      switch (result[0].data.nome) {
        case "Customer Experience":
          tmpCountSurveys.customer += 1;
          break;

        case "Brand Experience":
          tmpCountSurveys.brand += 1;
          break;

        case "Product Experience":
          tmpCountSurveys.product += 1;
          break;

        case "Employee Experience":
          tmpCountSurveys.employee += 1;
          break;

        case "Gift Surveys":
          tmpCountSurveys.gift += 1;
          break;

        default:
      }

      reports[i].data.nome_projeto = result[0].data.nome;
    }

    setCountSurveys(tmpCountSurveys);
    tmpCountSurveys = initialValues.countSurveys;
  };

  const ResetInfosSignOut = () => {
    setReports(null);
    setUser(initialValues.user);
    setCurrentTheme({});
    setCountSurveys(initialValues.countSurveys);
  };

  const getProject = (idProject) =>
    projects.filter((data) => data.id === idProject);

  const getTheme = async (idTheme) => {
    let result = themes.filter((data) => data.id === idTheme);

    setCurrentTheme(JSON.parse(result[0].data.json));
  };

  const getCompany = (nameCompany) =>
    companies.filter((data) => data.id === nameCompany);

  const getReport = (arrReports, idReports) => {
    let ArrayReports = [];

    for (let i = 0; i < idReports.length; i++) {
      let result = arrReports.filter(({ id }) => id === idReports[i]);
      ArrayReports.push(result[0]);
    }

    return ArrayReports;
  };

  // Funções de Filtragem
  const FilterList = (type, index) => {
    let ClassActiveExist = document
      .querySelectorAll(".box-category")
      [index].classList.contains("activeBox");
    let elActive = document.querySelector(".activeBox");

    if (elActive !== null) {
      elActive.classList.remove("activeBox");
    }

    if (ClassActiveExist) {
      ListFilterElements(index);
    } else {
      ListAllElements(index, type);
    }

    ResetNumberActive();
  };

  const FilterListMobile = (type) => {
    let { offset, perPage } = paginateConfigs;
    let filterItems = reports.filter(({ data }) => data.nome_projeto === type);

    let { sum, arrTmpNumbers } = VerifyMaxRange(filterItems.length);

    let ItemsToView = filterItems.slice(offset, perPage);

    setPaginateConfigs({
      ...paginateConfigs,
      pageCount: sum,
      currentPage: 1,
      indexPage: 1,
      oldData: filterItems,
      dataToView: ItemsToView,
      numbers: arrTmpNumbers,
      data: filterItems,
    });

    ResetNumberActive();
  };

  const ListAllElements = (index, type) => {
    const { offset, perPage } = paginateConfigs;

    document
      .querySelectorAll(".box-category")
      [index].classList.add("activeBox");

    let filterItems = reports.filter(({ data }) => data.nome_projeto === type);

    let { sum, arrTmpNumbers } = VerifyMaxRange(filterItems.length);

    let ItemsToView = filterItems.slice(offset, perPage);

    setPaginateConfigs({
      ...paginateConfigs,
      pageCount: sum,
      currentPage: 1,
      indexPage: 1,
      oldData: filterItems,
      dataToView: ItemsToView,
      numbers: arrTmpNumbers,
      data: filterItems,
    });
  };

  const ListFilterElements = (index) => {
    const { offset, perPage } = paginateConfigs;

    document
      .querySelectorAll(".box-category")
      [index].classList.remove("activeBox");

    let result = reports.slice(offset, perPage);

    let { sum, arrTmpNumbers } = VerifyMaxRange(reports.length);

    setPaginateConfigs({
      ...paginateConfigs,
      pageCount: sum,
      currentPage: 1,
      indexPage: 1,
      oldData: reports,
      dataToView: result,
      numbers: arrTmpNumbers,
      data: reports,
    });
  };

  const ResetDataToView = () => {
    let { offset, perPage } = paginateConfigs;

    let result = reports.slice(offset, perPage);

    let { sum, arrTmpNumbers } = VerifyMaxRange(reports.length);

    setPaginateConfigs({
      ...paginateConfigs,
      pageCount: sum,
      numbers: arrTmpNumbers,
      data: reports,
      oldData: reports,
      dataToView: result,
    });
  };

  const ResetNumberActive = () => {
    let el = document.querySelector(".activeNumber");
    let allEl = document.querySelectorAll(".numbersOfPage");

    if (el !== null) {
      el.classList.remove("activeNumber");
      allEl[0].classList.add("activeNumber");
    }
  };

  const VerifyMaxRange = (ArrLength) => {
    const { perPage } = paginateConfigs;
    let arrTmpNumbers = [];

    let sum = Math.ceil(ArrLength / perPage);

    if (sum !== 0) {
      for (let i = 1; i <= sum; i++) {
        arrTmpNumbers.push(i);
      }
    } else {
      arrTmpNumbers.push(1);
    }

    return { sum, arrTmpNumbers };
  };

  React.useMemo(() => {
    if (companies !== null && reports !== null && userInformation !== null) {
      getUser();
    }
  }, [companies, reports, userInformation]);

  React.useEffect(() => {
    Auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        getAll();
      } else {
        setUser(initialValues.user);
      }
    });
  }, []);

  return (
    <MainContext.Provider
      value={{
        inputValue,
        setInputValue,
        user,
        getUser,
        setUser,
        currentTheme,
        setCurrentTheme,
        reports,
        themes,
        setThemes,
        companies,
        getCompany,
        openSidebar,
        setOpenSidebar,
        getProject,
        countSurveys,
        infoDashboard,
        setInfoDashboard,
        viewFilter,
        setViewFilter,
        paginateConfigs,
        setPaginateConfigs,
        ResetNumberActive,
        FilterList,
        VerifyMaxRange,
        ResetDataToView,
        FilterListMobile,
        ResetInfosSignOut,
      }}
    >
      <ThemeProvider theme={currentTheme}>
        <Container>{children}</Container>
      </ThemeProvider>
    </MainContext.Provider>
  );
}

export { MainProvider, MainContext };
