import React from "react";
import { useHistory } from 'react-router-dom';
import contactUsService from "../../Services/contactUsService";
import { notification } from "../../Services/toastService";
import ArrowGoBack from "../../Components/ArrowGoBack";
import Loader from "../../Components/Loader";
import { MainContext } from '../../Context/mainContext';
import Layout from '../../Components/Layout';

import './styles.scss';

function NewProject() {
  const { user, loading } = React.useContext(MainContext);
  const [inputValue, setInputValue] = React.useState({
    nome: '',
    fone: '',
    assunto: '',
    mensagem: ''
  });
  const history = useHistory();

  const handleForm = async (evt) => {
    evt.preventDefault();
    await contactUsService
      .createNewContactUs(inputValue.nome, user.userInformations.email, inputValue.fone, inputValue.assunto, inputValue.mensagem)
      .then((res) => {
        setInputValue({
          nome: '',
          fone: '',
          assunto: '',
          mensagem: ''
        })
        notification("Sua mensagem foi enviada", "success");
      })
      .catch((err) => {
        notification(err.message, "warning");
      });
  }

  if (loading) {
    return <Loader />
  }else {
    return ( 
      <Layout title="Fale Conosco">
        <article id="container-faleConosco">
          <ArrowGoBack route="" history={history}/>

          <form id="formContactUS" onSubmit={evt => handleForm(evt)}>
            <header id="container-header-form-contactUS">
              <h1>DEIXE SUA MENSAGEM PARA NÓS!</h1>
            </header>
            
            <section id="container-form-contactUS">
              <section className="container-input-contactUS">
                <label htmlFor="nome">NOME</label>
                <input 
                  type="text"
                  name="nome"
                  placeholder="DIGITE SEU NOME" 
                  onChange={evt => setInputValue({...inputValue, nome: evt.target.value})}
                  value={inputValue.nome}
                />
              </section>

              <section className="container-input-contactUS">
                <label htmlFor="fone">FONE</label>
                <input 
                  type="text"
                  name="fone"
                  placeholder="DIGITE O NÚMERO DO SEU TELEFONE" 
                  onChange={evt => setInputValue({...inputValue, fone: evt.target.value})}
                  value={inputValue.fone}
                />
              </section>

              <section className="container-input-contactUS">
                <label htmlFor="assunto">ASSUNTO</label>
                <input 
                  type="text"
                  name="assunto"
                  placeholder="DIGITE O ASSUNTO"
                  onChange={evt => setInputValue({...inputValue, assunto: evt.target.value})}
                  value={inputValue.assunto}
               />
              </section>

              <section className="container-input-contactUS">
                <label htmlFor="mensagem">MENSAGEM</label>
                <textarea 
                  name="mensagem"
                  id="mensagem"
                  cols="30"
                  placeholder="DIGITE UMA MENSAGEM"
                  onChange={evt => setInputValue({...inputValue, mensagem: evt.target.value})}
                  value={inputValue.mensagem}
                >                    
                </textarea>
              </section>

              <button className="btn" type="submit">ENVIAR</button>
            </section>
          </form>
        </article>
      </Layout>
    )    
  }
}

export default NewProject;
