import styled from 'styled-components';

const Container = styled.section`
	.header-main, .dropdown-content, .sidenav {
		${props => props.theme.header};
	}

	.btn {
		${props => props.theme.button};
	}
`;

export default Container;