import React from "react";

function Pagination(props) {
  const { setPaginateConfigs, paginateConfigs } = props;

  const {
    currentPage,
    pageCount,
    numbers,
    indexPage,
  } = paginateConfigs;

  const PrevList = () => {
    setPaginateConfigs({
      ...paginateConfigs,
      currentPage: currentPage - 1,
      indexPage: indexPage - 1,
    });

    AddClassActive("prev");
  };

  const NextList = () => {
    setPaginateConfigs({
      ...paginateConfigs,
      currentPage: currentPage + 1,
      indexPage: indexPage + 1,
    });

    AddClassActive("next");
  };

  const ChangeList = (item, e) => {
    let el = document.querySelector(".activeNumber");

    if (el !== null) {
      el.classList.remove("activeNumber");
    }

    e.target.classList.add("activeNumber");

    setPaginateConfigs({
      ...paginateConfigs,
      currentPage: item,
      indexPage: item,
    });
  };

  const AddClassActive = (type) => {
    let el = document.querySelector(".activeNumber");
    let allEl = document.querySelectorAll(".numbersOfPage");

    if (el !== null) {
      el.classList.remove("activeNumber");
    }

    if (allEl[indexPage] !== undefined && type === "next") {
      allEl[indexPage].classList.add("activeNumber");
    } else if (allEl[indexPage - 2] !== undefined) {
      allEl[indexPage - 2].classList.add("activeNumber");
    }
  };

  return (
    <div className="pagination">
      <button
        disabled={currentPage === 1 ? true : false}
        onClick={() => PrevList()}
      >
        &laquo;
      </button>
      {numbers.map((item, index) => {
        if (index < 1) {
          return (
            <button
              className="numbersOfPage activeNumber"
              onClick={(e) => ChangeList(item, e)}
              key={index}
            >
              {item}
            </button>
          );
        } else {
          return (
            <button
              className="numbersOfPage"
              onClick={(e) => ChangeList(item, e)}
              key={index}
            >
              {item}
            </button>
          );
        }
      })}
      <button
        disabled={currentPage >= pageCount ? true : false}
        onClick={() => NextList()}
      >
        &raquo;
      </button>
    </div>
  );
}

export default Pagination;
