import React from "react";
import "./styles.scss";
import FolderIcon from "../../Assets/img/icons/folder.png";
import DashboardIcon from '../../Assets/img/icons/dashboard.png';

function TableItens({ data }) {
  const Redirect = link => {
    window.open(link, "_blank");
  };

  return (
    <>
      {data.map(({ data }, index) => {
        return (
          <tr
            onClick={() => Redirect(data.link)}
            className="row-list-item"
            key={index}
          >
            <td>
              {data.link.match(/datastudio/) !== null ? (
                <img src={DashboardIcon} alt="Dashboard" />
              ) : (
                <img src={FolderIcon} alt="Folder" />
              )}{" "}
              {data.nome}
            </td>
            <td>{data.nome_projeto}</td>
            <td>{data.data}</td>
          </tr>
        );
      })}
    </>
  );
}

export default TableItens;
