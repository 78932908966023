import React from "react";
import "../styles.scss";
import { Link } from "react-router-dom";
import { MainContext } from "../../../../Context/mainContext";

function User121Menu({ SignOut }) {
  const { user } = React.useContext(MainContext);

  return (
    <section className="dropdown-container">
      <div className="header-dropdown">
        <h3>
          {user.userInformation.nome}({user.companyInfo.nome})
        </h3>
      </div>

      <div className="dropdown-content">
        <Link to="/relatorios-pesquisa" className="dropdown-item">
          INICIO
        </Link>
        <Link to="/faleconosco" className="dropdown-item">
          FALE CONOSCO
        </Link>
        <Link to="/alterarsenha" className="dropdown-item">
          ALTERAR A SENHA
        </Link>
        <span onClick={(e) => SignOut(e)} className="dropdown-item">
          SAIR
        </span>
      </div>
    </section>
  );
}

export default User121Menu;
