import React from "react";
import { Link } from "react-router-dom";
import HeaderUserConfigs from "../HeaderUserConfigs/MenusDropdown";
import LogicMenuResponsive from "../HeaderUserConfigs/MenuResponsive/";
import MenuHamburguer from "../HeaderUserConfigs/MenuHamburguer/";
import { MainContext } from "../../Context/mainContext";

import "./styles.scss";

import Logo from "../../Assets/img/logo121.png";

function Header({ buttonVisible, Title = "Experience Lab" }) {
  const { currentTheme } = React.useContext(MainContext);

  return (
    <header className="header-main">
      <section className="container-header-main">
        <div className="column-header-main">
          <Link to="/relatorios-pesquisa">
            <img src={Logo} alt="Logo 121labs" />
            {currentTheme.logos === undefined
              ? null
              : currentTheme.logos.links.map((link, index) => {
                  return <img key={index} src={link} alt="" />;
                })}
          </Link>
        </div>

        <div className="column-header-main">
          <Link to="/relatorios-pesquisa">
            <h1 className="title-header-main">{Title}</h1>
          </Link>
        </div>

        <nav className="column-header-main nav-desktop">
          <HeaderUserConfigs />
        </nav>

        <nav className="column-header-main nav-header button-mobile">
          <MenuHamburguer />
        </nav>
      </section>

      <LogicMenuResponsive />
    </header>
  );
}

export default Header;
