import React from "react";
import Header from "../Header";
import "./styles.scss";
import ChangeTitle from '../Helmet';

function Layout({ children, title }) {
  return (
    <>
    	<ChangeTitle title={title}/>

      <Header />

      <article className="container">{children}</article>
    </>
  );
}

export default Layout;
