import {
  firebaseAuth
} from '../Config/firebase.js';
import * as firebase from 'firebase/app';
export const Auth = firebaseAuth;

export default class AuthService {
  // Serviço de login
  static signIn = async (email, password) => {
    return await firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  // Serviço de logout
  static signOut = () => {
    firebaseAuth.signOut().then(() => {
      localStorage.removeItem('@121website_user');
    });
  }

  // Servico para alterar a senha do usuario
  static reAuth = async (password, newPassword) => {
    const user = firebaseAuth.currentUser;

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    let status = {};

    await user.reauthenticateWithCredential(credential).then(async () => {
      await user.updatePassword(newPassword).then(async () => {
        status = {
          message: 'Senha alterada',
          type: 'success'
        };
      }).catch(() => {
        status = {
          message: 'Não foi possível alterar a senha',
          type: ''
        };
      });
    }).catch(() => {
      status = {
        message: 'Senha atual incorreta',
        type: ''
      };
    });

    return status;
  }
}