/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./styles.scss";
import { CgSearch, CgOptions } from "react-icons/cg";
import * as Icon from "../../Components/Icons";
import Layout from "../../Components/Layout";
import { MainContext } from "../../Context/mainContext";
import Loader from "../../Components/Loader/";
import BoxCategories from "../../Components/BoxCategories";
import TableItens from "../../Components/TableItens";
import Pagination from "../../Components/Pagination";
import FilterOptions from '../../Components/FilterOptions';

function Welcome() {
  const { 
    reports,
    countSurveys,
    viewFilter,
    setViewFilter,
    paginateConfigs,
    setPaginateConfigs,
    ResetNumberActive,
    FilterList,
    VerifyMaxRange
  } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(true);

  const {
    perPage,
    currentPage,
    offset,
    data,
    oldData,
    dataToView
  } = paginateConfigs;

  React.useEffect(() => {
    if (reports !== null) {
      reports.forEach((item, index) => {
        reports[index].data.nome = item.data.nome.toLowerCase();
      });

      let result = reports.slice(offset, perPage);

      let { sum, arrTmpNumbers } = VerifyMaxRange(reports.length);

      setPaginateConfigs({
        ...paginateConfigs,
        pageCount: sum,
        numbers: arrTmpNumbers,
        data: reports,
        oldData: reports,
        dataToView: result
      });

      setLoading(false);
    }
  }, [reports]);

  React.useMemo(() => {
    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    let sum = data.slice(indexOfFirst, indexOfLast);

    setPaginateConfigs({
      ...paginateConfigs,
      dataToView: sum
    });
  }, [currentPage]);

  const SearchReport = (e) => {
    let valueInput = e.target.value.toLowerCase();
    let filterItems = data.filter(({ data }) => data.nome.match(valueInput));

    if (valueInput.length > 0) {
      let ItemsToView = filterItems.slice(offset, perPage);

      let { sum, arrTmpNumbers } = VerifyMaxRange(filterItems.length);

      setPaginateConfigs({
        ...paginateConfigs,
        pageCount: sum,
        numbers: arrTmpNumbers,
        data: filterItems,
        dataToView: ItemsToView
      });

      ResetNumberActive();
    } else {
      let { sum, arrTmpNumbers } = VerifyMaxRange(oldData.length);

      setPaginateConfigs({
        ...paginateConfigs,
        pageCount: sum,
        numbers: arrTmpNumbers,
        data: oldData,
        dataToView: oldData
      });
    }
  }

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Layout title="Relatórios de Pesquisa">
        <article className="container-welcome">
          <section id="banner-welcome">
            <h1>Relatórios de Pesquisa</h1>

            <section id="container-input-filter">
              <div id="button-filter-container" onClick={() => setViewFilter(!viewFilter)}>
                Filtro
                <CgOptions />
              </div>

              <input
                type="text"
                name="filter"
                id="filter"
                placeholder="Pesquisar Relatório"
                onInput={(e) => SearchReport(e)}
              />

              <CgSearch id="search-icon" />
            </section>
          </section>

          <section id="categories-welcome">
            <BoxCategories FilterList={FilterList} index={0} type="Customer Experience">
              <Icon.Customer />
              <h4>Customer Experience ({countSurveys.customer})</h4>

              <div className="tooltip">
                <ul>
                  <li>Experiência do Consumidor</li>
                  <li>IDSS (ANS)</li>
                  <li>Monitoramento e Pesquisas de Satisfação</li>
                </ul>
              </div>
            </BoxCategories>

            <BoxCategories FilterList={FilterList} index={1} type="Brand Experience">
              <Icon.Brand />
              <h4>Brand Experience ({countSurveys.brand})</h4>

              <div className="tooltip">
                <ul>
                  <li>Experiência de Marca</li>
                  <li>CBBE (Valor de Marca)</li>
                  <li>Confiança do Consumidor</li>
                </ul>
              </div>
            </BoxCategories>

            <BoxCategories FilterList={FilterList} index={2} type="Product Experience">
              <Icon.Product />
              <h4>Product Experience ({countSurveys.product})</h4>

              <div className="tooltip">
                <ul>
                  <li>Experiência de Produto e Serviço</li>
                  <li>Inovação em Produto/Serviço</li>
                  <li>Análise Combinatória</li>
                </ul>
              </div>
            </BoxCategories>

            <BoxCategories FilterList={FilterList} index={3} type="Employee Experience">
              <Icon.Employee />
              <h4>Employee Experience ({countSurveys.employee})</h4>

              <div className="tooltip">
                <ul>
                  <li>Pesquisa de Clima Colaboradores</li>
                  <li>Avaliação de Engajamento</li>
                  <li>e-NPS</li>
                </ul>
              </div>
            </BoxCategories>

            <BoxCategories FilterList={FilterList} index={4} type="Gift Surveys">
              <Icon.Gift />
              <h4>Gift Surveys (Bônus) ({countSurveys.gift})</h4>

              <div className="tooltip">
                <ul>
                  <li>Projetos e Pesquisas Bônus 121</li>
                </ul>
              </div>
            </BoxCategories>
          </section>

          <section id="list-reports">
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Projeto</th>
                    <th>Categoria</th>
                    <th>Período</th>
                  </tr>
                </thead>

                <tbody>
                  <TableItens data={dataToView} />
                </tbody>
              </table>
            </div>

            <footer id="footer-table-reports">
              <h3>Foram encontrados {data.length} relatórios</h3>

              <Pagination
                setPaginateConfigs={setPaginateConfigs}
                paginateConfigs={paginateConfigs}
              />
            </footer>
          </section>
        </article>

        <FilterOptions />
      </Layout>
    );
  }
}

export default Welcome;
