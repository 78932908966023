import React from "react";
import "../styles.scss";
import { MainContext } from "../../../../Context/mainContext";
import { Link } from "react-router-dom";

function User121Menu({ SignOut }) {
  const {
    user,
    openSidebar,
    setOpenSidebar,
  } = React.useContext(MainContext);

  return (
    <div
      className="sidenav"
      style={{ width: `${openSidebar.User121 ? "250px" : "0px"}` }}
    >
      <header className="header-sidenav">
        <div>
          <h5>
            {user.userInformation.nome.toUpperCase()} (
            {user.companyInfo.nome.toUpperCase()})
          </h5>
        </div>
        <span onClick={() => setOpenSidebar(false)} className="closebtn">
          &times;
        </span>
      </header>

      <section>
        <Link to="/relatorios-pesquisa">INICIO</Link>
        <Link to="/faleconosco">FALE CONOSCO</Link>
        <Link to="/alterarsenha">ALTERAR SENHA</Link>
        <span onClick={(e) => SignOut(e)}>SAIR</span>
      </section>
    </div>
  );
}

export default User121Menu;
