import { firebaseAuth, firebaseFirestore } from "../Config/firebase.js";

export default class UserService {
  static getCurrentUserEmail = () => {
    return firebaseAuth.currentUser.email;
  };

  static getAllCompanies = async () => {
    let querySnapshot = await firebaseFirestore.collection("empresa").get();
    let companies = [];

    querySnapshot.forEach((document) => {
      companies.push({ id: document.id, data: document.data() });
    });

    return companies;
  };

  static getCompanyUser = async (idCompany) => {
    let docRef = await firebaseFirestore.collection("empresa").doc(idCompany);
    let empresaInfo;

    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          empresaInfo = doc.data();
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    return empresaInfo;
  };

  static getUserInformation = async () => {
    let userEmail = await this.getCurrentUserEmail();

    let querySnapshot = await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get();
    let dataToReturn = null;

    querySnapshot.forEach((document) => {
      dataToReturn = document.data();
    });

    return dataToReturn;
  };

  static getAllUsers = async () => {
    let querySnapshot = await firebaseFirestore
      .collection("users")
      .orderBy("nome", "asc")
      .get();
    let users = [];

    let emailUser = await this.getCurrentUserEmail();

    querySnapshot.forEach((document) => {
      let { email } = document.data();

      if (email === emailUser) {
        return null;
      } else {
        users.push(document.data());
      }
    });

    return users;
  };
}
