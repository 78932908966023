import React from "react";

import "./styles.scss";

function BoxCategories({ children, FilterList, type, index }) {
  return (
    <div className="box-category" onClick={() => FilterList(type, index)}>
      {children}
    </div>
  );
}

export default BoxCategories;
