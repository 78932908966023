import React from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import "./styles.scss";

function ArrowGoBack(props) {
  function handleClick() {
    if (props.route === "") {
      props.history.goBack();
    } else {
      props.history.push(props.route);
    }
  }

  return (
    <div className="container-btnToBack">
      <button className="btnToBack" onClick={() => handleClick()}>
        <BsArrowLeftShort />
      </button>
    </div>
  );
}

export default ArrowGoBack;
